import React, { useCallback } from 'react'

import CallToAction from 'components/callToAction'
import HeroComponent from 'components/hero'
import HseqSection from 'components/hseqSection'
import Layout from 'components/layout'
import Seo from 'components/seo'

import { graphql } from 'gatsby'

const HseqPage = ({ data, location }) => {
  const {
    sanityHseqPage: {
      tabsHseq: { seo, Hero, Call_to_Action, Hseq },
    },
    sanityAppereance: {
      tabsAppereance: { header, footer },
    },
    allSanityBlogPost,
  } = data

  const setCallToActionBtn = useCallback(() => {
    console.log('click btn contáctanos')
  }, [])

  return (
    <Layout header={header} footer={footer}>
      {seo?.seo?.focus_keyword && seo?.author && seo?.seo?.focus_synonyms && (
        <Seo data={seo} />
      )}
      {Hero?.enable && (
        <HeroComponent
          navbar={Hero?.logoEmpresaHeader?.image?.asset?.url}
          navbarAlt={Hero?.logoEmpresaHeader?.alt}
          imageBackground={Hero?.image.image.asset.url}
          title={Hero?.title}
          subTitle={Hero?.description}
          btnText={Hero?.cta?.text}
          btnCallToAction={setCallToActionBtn}
          btnEnable={Hero?.cta?.enable}
          btnLink={Hero?.cta?.link}
          location={location.pathname}
        />
      )}
      {Hseq?.enable && (
        <HseqSection
          title={Hseq?.hseq?.text}
          description={Hseq?.hseq?._rawDescription}
          nodos={allSanityBlogPost?.nodes}
        />
      )}

      {Call_to_Action?.enable && (
        <CallToAction
          title={Call_to_Action?.title}
          paragraph={Call_to_Action?.description}
          btnText={Call_to_Action?.cta.text}
          btnEnable={Call_to_Action?.cta.enable}
          btnCallToAction={setCallToActionBtn}
        />
      )}
    </Layout>
  )
}

export const HseqQuery = graphql`
  query HseqQuery {
    sanityHseqPage(slug: { current: { eq: "hseq" } }) {
      tabsHseq {
        seo {
          author
          _key
          seo {
            seo_title
            meta_description
            focus_synonyms
            focus_keyword
            _type
            _key
          }
          ogImage {
            asset {
              url
            }
          }
        }
        Hero {
          description
          enable
          title
          cta {
            enable
            link
            text
          }
          image {
            image {
              asset {
                url
              }
            }
            alt
          }
          logoEmpresaHeader {
            image {
              asset {
                url
              }
            }
            alt
          }
        }
        Call_to_Action {
          description
          enable
          title
          cta {
            enable
            link
            text
          }
        }
        Hseq {
          enable
          hseq {
            _rawDescription
            text
            enable
          }
        }
      }
    }
    sanityAppereance(slug: { current: { eq: "apariencia" } }) {
      tabsAppereance {
        footer {
          footerTitle
          footerParagrah
          enableFooter
          direccion
          departamento
          correo3
          correo2
          correo1
          logoEmpresaFooter {
            alt
            image {
              asset {
                url
              }
            }
          }
        }
        header {
          correo
          enableHeader
          linkFacebook
          linkInstagram
          linkLinkedin
          linkTwitter
          messenger
          telefono
        }
      }
    }
    allSanityBlogPost(limit: 4, sort: { fields: _id, order: DESC }) {
      nodes {
        title
        subtitle
        hseq
        image {
          image {
            asset {
              url
            }
          }
        }
        slug {
          current
        }
        text {
          children {
            text
          }
        }
      }
    }
  }
`

export default HseqPage
