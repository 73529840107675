import React, { useEffect } from 'react'
import Blog from 'components/blog'
import BlockText from 'components/ui/block-text'

const HseqSection = ({ title, description, nodos }) => {
  const formatText = (str, max, suffix) => {
    return str.length < max
      ? str
      : `${str.substr(
          0,
          str.substr(0, max - suffix.length).lastIndexOf(' ')
        )}${suffix}`
  }
  useEffect(() => {
    console.log('nodos', nodos)
  })

  return (
    <div className="container hseqSection">
      <div className="encabezado col-10 mx-auto">
        <h3 className="encabezado-title text-center">{title}</h3>
        <div className="encabezado-description text-center my-5 col-10 mx-auto">
          <BlockText data={description} />
        </div>
      </div>

      <div className="blogs">
        {nodos?.map((post, index) => (
          <div className="blogHseq">
            {nodos[index]?.hseq && post?.text?.length > 0 && (
              <Blog
                imgPrincipal={post?.image?.image?.asset?.url}
                titleBlog={post?.title}
                subTitleBlog={post?.subtitle}
                contentBlog={formatText(
                  post?.text[0]?.children[0]?.text,
                  500,
                  '.'
                )}
                linkBtn={`/blog/${post?.slug?.current}`}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default HseqSection
