import React from 'react'
import PropTypes from 'prop-types'

import { navigate } from 'gatsby'

const CallToAction = ({ title, paragraph, btnText, btnEnable, btnLink }) => {
  return (
    <div className="container-fluid py-5" id="callToAction">
      <div className="callToAction container text-center d-flex flex-column align-items-center my-4 py-1 py-lg-0">
        <div className="callToAction-text col-12 col-lg-10 d-flex flex-column align-items-center">
          <h3 className="callToAction-text--h3 col-12">{title}</h3>
          <p className="callToAction-text--p col-10 col-lg-9 my-2">
            {paragraph}
          </p>
        </div>
        <div className="callToAction-btn col-12 mt-4 mt-lg-0">
          {btnEnable && (
            <button
              className="callToAction-btn--btn col-9 col-md-6 col-lg-3 py-2 mt-md-4"
              onClick={() => {
                navigate(`/${btnLink}`)
              }}
            >
              {btnText}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

CallToAction.propTypes = {
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  btnCallToAction: PropTypes.func.isRequired,
}

export default CallToAction
