import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

const Blog = ({
  imgPrincipal,
  titleBlog,
  subTitleBlog,
  contentBlog,
  linkBtn,
}) => {
  const [width, setWidth] = useState(null)
  const ref = useRef(null)
  useEffect(() => {
    setWidth(ref.current ? ref.current.offsetWidth : 0)
  }, [ref.current])
  return (
    <div className="container">
      <div className="entradaBlog d-flex flex-column flex-lg-row mx-auto col-12 my-4 my-lg-5 pt-lg-5">
        <div className="entradaBlog-img mx-auto col-lg-3 col-11 mb-3 mb-lg-0">
          <div
            className="entradaBlog-img--img shadow col-10 col-lg-12 mx-auto"
            style={{
              backgroundImage: `url(${imgPrincipal})`,
            }}
            ref={ref}
          ></div>
        </div>
        <div className="entradaBlog-text col-11 mx-auto col-lg-9 px-2 px-lg-5">
          <h3 className="entradaBlog-text--h3 text-center text-lg-start">
            {titleBlog}
          </h3>
          <h6 className="entradaBlog-text--h6">{subTitleBlog}</h6>
          <p className="entradaBlog-text--p">{contentBlog}</p>
          <a
            className="hero-text--button btn col-10 col-lg-3 my-2 offset-1 offset-lg-0"
            href={linkBtn}
          >
            Leer mas..
          </a>
        </div>
      </div>
    </div>
  )
}

Blog.propTypes = {
  imgPrincipal: PropTypes.string.isRequired,
  titleBlog: PropTypes.string.isRequired,
  subTitleBlog: PropTypes.string.isRequired,
  contentBlog: PropTypes.string.isRequired,
}

export default Blog
